#hero {
    transition: all .5s;
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
    background-size: cover;
    background-position: center center;

    img {
        width: 15%;
        height: auto;
    }

    .redes {
        position: absolute;
        bottom: 3%;
        left: 2%;

        a {
            margin: 10px 0px;
            display: block;
            font-size: 25px;
            color: #FF2956;
            transition: color 1s;
        }

        a:hover {
            color: lighten(#FF2956, 10);
            animation: glitch;
            animation-iteration-count: infinite;
            animation-duration: .3s;
        }
    }
}

// here we add all the container that are wider
@media screen and (min-width: 1200px) {

    footer,
    #somos {
        .container {
            width: 80%;
        }
    }
}

#contacto {
    background-image: url(../images/back_empecemos2.jpg);
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    padding: 120px 0px;

    h1 {
        color: #FF2956;
        font-weight: bolder;
        font-size: 40px;
        letter-spacing: 3px;

    }

    p {
        color: #EAEAEA;
    }

    .center {
        text-align: center;
    }

    .right {
        text-align: right;
    }

    input {
        border: none;
        border-bottom: 1px solid #EAEAEA;
        background: rgba(0, 0, 0, 0);
        font-size: 20px;
        margin: 30px 0px;
        padding: 3px 0;
        color: #EAEAEA;
        width: 80%;
        transition: all 1s;
    }

    input:focus {
        border-bottom: 1px solid #FF2956;
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #EAEAEA;
        opacity: 1;
        /* Firefox */
    }

    .large_message {
        width: 100%;
    }

    button {
        padding: 8px 10px;
        background: rgba(0, 0, 0, 0);
        border: 1px solid #EAEAEA;
        color: #EAEAEA;
        font-size: 18px;
        letter-spacing: 3px;
        margin-top: 30px;
        transition: all 1s;
    }

    button:hover {
        animation: glitch;
        animation-iteration-count: infinite;
        animation-duration: .5s;
        border: 1px solid #FF2956;
        background: #FF2956;
    }
}

svg {
    animation: glitch;
    animation-iteration-count: infinite;
    animation-duration: 1s;
}

#exito {
    height: 100vh;
    padding: 15% 0;
    background: #000000;
    .container{
        width: 100%;
    }
    .center {
        text-align: center;
    }

    svg {
        margin-top: -15%;
    }

    h1 {
        color: #FF2956;
        font-weight: bolder;
        font-size: 40px;
        letter-spacing: 3px;
        z-index: 3;
    }

    img {
        z-index: 3;
        margin: 40px 0px;
        max-height: 90px;
        max-width: 90%;
    }

    .middle_image {
        margin: 70px 0;
    }

    .last_image {
        margin: 50px 0px;
        max-height: 60px;
    }

    .col-md-2 {
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    #exito {
        padding: 50px 0px;

        img {
            margin: 20px 0px !important;
            max-height: 90px;
        }
    }
}


#hacemos {
    transform-origin: 0 0;
    transform: translate(0%);
    transition: transform 2s cubic-bezier(.77,.2,.05,1);
    background: white;
    height: 100vh;
    overflow: hidden;

    .slide {
        position: relative;
        height: 100vh;
        width: 100vw;
    }
}

#somos {
    padding: 80px 0px;
    background: #000000;

    h1 {
        color: #FF2956;
        font-weight: bolder;
        font-size: 40px;
        letter-spacing: 3px;
        z-index: 3;
        margin-bottom: 50px;
    }
    h3 {
        color: #FF2956;
        font-weight: bolder;
        font-size: 60px;
        letter-spacing: 3px;
        z-index: 3;
        margin-bottom: 20px;
    }
    p {
        color: #EAEAEA;
        margin-bottom: 50px;
        font-size: 16px;
        z-index: 3;
        margin-left: 30%;
        text-align: justify;
        word-wrap: break-word;
    }

    h2 {
        font-size: 20px;
        color: #EAEAEA;
        margin-bottom: 20px;
        z-index: 3;
    }

    .right {
        text-align: right;
    }

    img {
        z-index: 3;
        width: 30%;
        min-width: 148px;
    }
}



@media screen and (max-width:768px) {
    #hero {
        img {
            width: 35%;
            height: auto;
        }

        .redes {
            position: absolute;
            bottom: 3%;
            left: 5%;

            a {
                margin: 10px 0px;
                font-size: 18px;
            }
        }
    }

    #somos {
        padding: 40px 0;

        .right {
            text-align: center;
        }

        p {
            margin-left: 5px;
            margin-right: 5px;
            //text-align: center;
            font-size: 17px;
            margin-bottom: 30px;
        }

        img {
            margin-left: 20%;
            margin-bottom: 20px;
        }

        h1 {
            margin-bottom: 20px;
            font-size: 25px;
        }

        h2 {
            margin-top: 50px;
            font-size: 20px;
        }
    }

    #exito {
        h1 {
            font-size: 25px;
            letter-spacing: 3px;
        }

        img {
            max-height: 90px;
            max-width: 41%;
        }
    }

}

#descanso {
    padding-top: 80px;
    height: 300px;
    width: 100%;
    background: black;

    svg {
        right: 0;
    }
}

section {
    position: relative;
    overflow: hidden;
}

svg {
    position: absolute;
    margin-top: -80px;
    height: 0%;
    width: 0%;
    z-index: 0;
}

@media screen and (max-width:768px) {
    #notANav {
        position: fixed;
        height: 70px;
        background: black;
        width: 100%;
        margin-top: 0px;
        z-index: 4000;
        padding: 0 5%;
    }

    #descanso {
        padding-top: 50px;
        height: 50vh;
        width: 100%;
        background: black;
    }

    svg {
        margin-top: -50px;
    }

    #contacto input {
        width: 100% !important;
    }
}

#menu {
    .redes {
        position: absolute;
        bottom: 3%;
        left: 2%;

        a {
            margin: 10px 0px;
            display: block;
            font-size: 25px;
            color: #FF2956;
            transition: all 1s;
        }

        a:hover {
            animation: glitch;
            animation-iteration-count: infinite;
            animation-duration: .2s;
            color: lighten(#FF2956, 10);
        }
    }
}

.glitch_this {
    animation: glitch;
    animation-iteration-count: infinite;
    animation-duration: .7s;
}

.remove_glitch {
    -webkit-filter: none;
    filter: none;
}


@keyframes glitch {
    from {
        -webkit-filter: url("#glitch");
        filter: url("#glitch");
    }

    to {
        -webkit-filter: none;
        filter: none;
    }
}

.glitch_this img {
    height: auto;
    max-width: 100%;
}

#hero {
    .logo_svg {
        height: 55%;
        width: 55%;
    }

    .st0 {
        stroke: #000000;
    }
}

#linea_hero {
    margin-top: 0px;
    width: 100%;
    height: 100vh;
}

#block_magic {
    display: block;
    position: absolute;
    width: 100%;
    height: 100vh;
}

.slide {
    p {
        font-size: 16px;
    }

    .corner_logo {
        position: absolute;
        height: 100px;
        bottom: 5%;
        right: 4%;
    }
}

.slide0 {
    position: relative;

    img {
        position: absolute;
    }

    .image1 {
        top: 20%;
        right: 10%;
        width: 30%;
        max-width: 500px;
    }

    .image2 {
        max-width: 110px;
        right: 0;
        width: 6.5%;
        top: 3%;
    }

    .image3 {
        width: 17%;
        max-width: 350px;
        bottom: 0;
        right: 0;
    }

    h1 {
        margin-top: 35%;
        font-size: 40px;
        font-weight: bolder;
        color: #FF2956;
        letter-spacing: 3px;
    }

    h2 {
        margin-top: 80%;
        color: #1A1A1A;
        font-size: 16px;
    }

    p {
        color: #1A1A1A;
    }

    hr {
        border: 1px solid #bdbdbd;
        width: 100%;
    }
}

.slide1 {
    position: relative;

    .box_texto {
        position: absolute;
        padding: 50px;
        background: #000000;
        width: 35%;
        top: 0;
        left: 50%;
    }

    .massive {
        width: 60%;
        top: 0;
        left: 10%;
        position: absolute;
    }

    h1 {
        font-size: 40px;
        font-weight: bolder;
        letter-spacing: 3px;
        color: #FF2956;

        margin-top: 25%;
    }

    p {
        font-size: 16px;
        color: #FBFBFB;
    }
}

.slide2 {
    .tech {
        width: 50%;
        bottom: 0%;
        left: 30%;
        position: absolute;
    }

    .col-md-3 {
        height: 60vh;
        background: #1A1A1A;

        h1 {
            margin-top: 80px;
            color: #FF2956;
            font-size: 40px;
            font-weight: bolder;
            letter-spacing: 3px;
            text-align: right;
        }
    }

    .col-md-6 {
        h1 {
            margin-top: 80px;
            font-size: 40px;
            font-weight: bolder;
            letter-spacing: 3px;
            color: #1A1A1A;
            margin-bottom: 30px;
        }
    }

    p {
        font-size: 16px;
        color: #1A1A1A;
    }
}

.slide3 {
    .team {
        width: 53%;
        bottom: 0;
        left: 35%;
        position: absolute;
    }

    .col-md-5 {
        background: black;
        padding: 40px;

        h1 {
            margin-top: 120px;
            margin-bottom: 80px;
            color: #FF2956;
            font-size: 40px;
            font-weight: bolder;
            letter-spacing: 3px;
            text-align: right;
        }
    }

    .col-md-4 {
        padding: 40px;

        h1 {
            margin-top: 120px;
            font-size: 40px;
            font-weight: bolder;
            letter-spacing: 3px;
            color: #1A1A1A;
            margin-bottom: 30px;
        }
    }

    p {
        font-size: 16px;
        color: #EAEAEA;
        line-height: 30px;
        text-align: justify;
    }
}

.slide4 {
    .black_box {
        background: #000000;
        padding: 50px 100px;

        h1 {
            color: #FF2956;
            font-size: 40px;
            font-weight: bolder;
            letter-spacing: 3px;
        }
    }

    .container {
        width: 90%;
    }

    .chava {
        width: 102%;
        margin-top: -1px;
    }

    .col-md-5 {
        padding: 40px;

        h1 {
            margin-top: 120px;
            font-size: 40px;
            font-weight: bolder;
            letter-spacing: 3px;
            color: #1A1A1A;
            margin-bottom: 30px;
        }
    }

    .col-md-4 {
        padding: 40px;
    }

    p {
        margin-top: 35%;
    }

    p,
    ul {
        font-size: 16px;
        color: #1A1A1A;
        line-height: 30px;
        text-align: justify;
    }
}

.slide5 {
    .black_box {
        background: #000000;
        padding: 50px 100px;

        h1 {
            color: #FF2956;
            font-size: 40px;
            font-weight: bolder;
            letter-spacing: 3px;
        }
    }

    .chava {
        width: 102%;
        margin-top: -1px;
    }

    .col-md-4 {
        padding: 40px;
    }

    p:nth-child(1) {
        margin-top: 35%;
    }

    p:nth-child(2) {
        margin-top: 25px;
    }

    p {
        font-size: 16px;
        color: #1A1A1A;
        line-height: 30px;
        text-align: justify;
    }
}

.slide6 {
    .box_mkt {
        position: absolute;
        width: 30%;
        top: 0;
        left: 50%;
    }

    .mkt {
        position: absolute;
        top: 20%;
        right: 50.7%;
        width: 33%;
    }

    .col-md-2 {
        height: 60vh;
        background: #1A1A1A;
    }
    .col-md-10{
        padding-left: 50px;
    }
    h1 {
        margin-top: 80%;
        color: #FF2956;
        font-size: 40px;
        font-weight: bolder;
        letter-spacing: 3px;
    }

    h2 {
        font-family: 'Roboto Condensed', sans-serif;
        margin-bottom: 31px;
        color: #1A1A1A;
        font-size: 40px;
        font-weight: bolder;
        letter-spacing: 3px;
    }

    p {
        font-size: 16px;
        color: #1A1A1A;
        line-height: 30px;
        text-align: justify;
    }
}


.slides{
    width: 700vw;
    height: 100vh;
}

#hacemos .slide{
    width: 98.9vw;
    overflow: hidden;
    float: left;
}
#hacemos{
    overflow: unset;
    width: 100%;
    .slides{
        .slide{
            .arrows{
                position: absolute;
                width: 10%;
                display: flex;
                bottom: 30%;
                right:  1%;
                z-index: 1000;
                justify-content: space-between;
                img{
                    position: relative;
                    cursor: pointer;
                    height: 41px;
                    width: auto;
                    animation: none;
                    animation-duration: .7s;
                    animation-iteration-count: infinite;
                }
            }
        }
    }
}
.mobile{
    display: none;
}

@media screen and (max-width: 768px) {
    #menu .redes a {
        margin: 8px 0;
        font-size: 18px;
        transition: all 1s;
    }
    #menu .redes {
        bottom: 2%;
        left: 5%;
    }
    #close{
        top: 2%;
    }
    #hero .logo_svg {
        height: 82%;
        width: 112%;
    }
    #somos h2 {
        font-size: 18px;
    }
    #somos p {
        font-size: 15px;
    }
    #descanso {
        padding-top: 50px;
        height: 40vh;
    }
    #exito {
        height: unset;
    }
    #linea_hero {
        margin-top: -25px;
    }
    #contacto input{
        font-size: 15px;
    }
    #hacemos{
        overflow: hidden;
    }
    #hacemos .slides .slide .arrows {
        position: absolute;
        width: 33%;
        display: -ms-flexbox;
        display: flex;
        bottom: 2%;
        right: 33%;
        z-index: 1000;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .slides{
        transform-origin: 0 0;
    transform: translate(0);
    transition: transform 2s cubic-bezier(.77,.2,.05,1);
    }
    .slide0 .image2 {
        max-width: 115px;
        right: 0px;
        width: 14.5%;
        top: 3%;
    }
    .slide0 .image1 {
        top: 47%;
        right: 13%;
        width: 48%;
        max-width: 500px;
    }
    .slide0 .image3 {
        width: 25%;
        max-width: 350px;
        bottom: 31%;
        right: 54%;
    }
    .slide1 .box_texto {
        position: absolute;
        padding: 30px;
        background: #000;
        width: 80%;
        top: 0;
        left: 20%;
    }
    #hacemos .slide {
        width: 100vw;
        overflow: hidden;
        float: left;
    }
    .slide1 .massive {
        width: 77%;
        top: unset;
        bottom: 22%;
        left: 0%;
        position: absolute;
    }
    .slide .corner_logo {
        position: absolute;
        height: 56px;
        bottom: 5%;
        right: 4%;
    }
    .slide2 .col-md-3 h1, .slide2 .col-md-6 h1 {
        font-size: 30px;
    }
    .slide4 .black_box h1, .slide5 .black_box h1 {
        font-size: 30px;
    }
    .slide2 .col-md-3 {
        width: 35%;
        float: left;
        height: 50vh;
        background: #1a1a1a;
    }

    .slide2 .col-md-6 {
        width: 65%;
        float: right;
        height: 50vh;
    }
    .slide2 .tech {
        width: 72%;
        bottom: 14%;
        left: 15%;
        position: absolute;
    }
    .slide4 .black_box {
        text-align: center;
        background: #000;
        padding: 50px 40px;
    }
    .mobile{
        display: block;
    }
    .slide4 .col-md-7{
        width: 100%;
    }
    .slide4 .container {
        width: 100%;
        padding: unset;
    }
    .desktop{
        display: none;
    }
    .slide4 p, .slide4 ul {
        font-size: 16px;
        color: #f2f2f2;
        line-height: 30px;
        text-align: center;
    }
    
    .slide4 p {
        margin-top: 12%;
    }
    .slide4 .black_box {
        text-align: center;
        background: #000;
        padding: 20px 40px;
    }
    .slide5 .black_box {
        background: #000;
        padding: 20px 55px;
        text-align: center;
    }
    .slide5 .chava {
        width: 61%;
    margin-left: -15px;
    margin-top: 200px;
    top: 23%;
    position: absolute;
    }
    .slide5 p:first-child {
        margin-top: 4%;
    }
    .slide6 .col-md-2 {
        width: 20%;
        float: left;
        height: 50vh;
    }
    .slide6 .col-md-10 {
        float: right;
        width: 80%;
        height: 50vh;
    }
    .slide6 .box_mkt {
        position: absolute;
        width: 80%;
        top: 0;
        left: 10%;
    }
    .slide6 h1, .slide6 h2 {
        font-size: 30px;
    }
    .slide6 .col-md-10 {
        padding-left: 15px;
    }

    .slide6 h1 {
        margin-top: 7%;
        color: #ff2956;
    }
    .slide6 .mkt {
        position: absolute;
        bottom: 15%;
        right: 24%;
        width: 52%;
        top: unset;
    }
    .slide3 .col-md-4 h1 {
        margin-top: 50px;
        margin-bottom: 0px;
        text-align: center;
        font-size: 30px;
        font-weight: bolder;
        letter-spacing: 3px;
        color: #1a1a1a;
        padding-bottom: 3px;
    }
    .slide3 .col-md-4 {
        padding: 40px;
        padding-bottom: 3px;
    }
    .slide3 .col-md-5 {
        background: #000;
        text-align: center;
        padding: 40px;
        padding-top: 3px;
    }
    .slide3 .col-md-5 h1 {
        margin-top: 10px;
        margin-bottom: 37px;
        color: #ff2956;
        font-size: 30px;
        font-weight: bolder;
        letter-spacing: 3px;
        text-align: center;
    }
    .slide3 .team {
        width: 70%;
        bottom: 14%;
        left: 0%;
        z-index: 20;
        position: absolute;
    }
}

