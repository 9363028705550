html {
  box-sizing: border-box; }

*,
:after,
:before {
  box-sizing: inherit;
  outline: 0; }

body {
  overflow-x: hidden;
  margin: 0px; }

@font-face {
  font-family: 'Gilroy';
  src: url("../fonts/Gilroy-ExtraBold.ttf");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Gilroy';
  src: url("../fonts/Gilroy-Light.ttf");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Gilroy';
  src: url("../fonts/Gilroy-Regular.ttf");
  font-weight: normal;
  font-style: normal; }

h1 {
  font-family: 'Roboto Condensed', sans-serif; }

h2, h3, h4, h5, h6, a, p, ul, li {
  font-family: 'Gilroy'; }

#notANav {
  position: fixed;
  height: 70px;
  width: 100%;
  margin-top: 5px;
  z-index: 4000;
  padding: 0px 3%; }
  #notANav .nav_right {
    line-height: 70px;
    float: right;
    font-size: 10px;
    text-decoration: none !important;
    -webkit-transition: all 1s;
    transition: all 1s;
    font-weight: lighter;
    cursor: pointer; }
    #notANav .nav_right i {
      margin-right: 10px;
      font-size: 24px; }
    #notANav .nav_right span {
      display: none;
      letter-spacing: 2px; }
  #notANav .nav_left {
    line-height: 70px;
    float: left;
    height: 70px;
    width: 200px; }
    #notANav .nav_left svg {
      height: 60px;
      -webkit-transition: all 1s;
      transition: all 1s; }

@media screen and (max-width: 768px) {
  #notANav {
    padding: 0px 5%; } }

#menu {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 50000;
  /* margin: -100px 0 0 -50px; */
  padding: 50px;
  padding-top: 125px;
  background: black;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: all 1s;
  transition: all 1s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: translate(100%);
          transform: translate(100%);
  -webkit-transition: -webkit-transform 2s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: -webkit-transform 2s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 2s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 2s cubic-bezier(0.77, 0.2, 0.05, 1), -webkit-transform 2s cubic-bezier(0.77, 0.2, 0.05, 1); }
  #menu .aasdfa {
    width: 47%; }
  #menu p {
    position: relative;
    color: #fff;
    font-weight: lighter;
    font-size: 30px;
    letter-spacing: 20px;
    display: inline-block;
    padding: 0px 5px;
    text-align: center;
    margin: 30px 0px;
    cursor: pointer; }
  #menu p:nth-child(even) {
    float: right; }
  #menu p::before {
    content: "";
    left: 0%;
    right: 0%;
    bottom: 46%;
    position: absolute;
    width: 95%;
    height: 3px;
    background-color: #ff2956;
    visibility: visible;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s; }
  #menu p:hover {
    -webkit-animation: glitch;
            animation: glitch;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-duration: .3s;
            animation-duration: .3s; }
  #menu p:hover::before {
    background-color: #ff2956 !important;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); }

#close {
  cursor: pointer;
  position: absolute;
  top: 5%;
  right: 3%;
  color: white;
  -webkit-transition: all 1s;
  transition: all 1s;
  font-size: 39px;
  text-decoration: none !important; }

.nav-toggle:hover {
  -webkit-animation: glitch;
          animation: glitch;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: .2s;
          animation-duration: .2s; }

.nav-toggle i {
  position: relative;
  margin-bottom: 4px;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  width: 25px;
  height: 3px;
  font: 700 14px/.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: #505050;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out; }

.nav-toggle:hover i:before {
  top: -10px; }

.logo_nav {
  height: 25px; }

.nav-toggle i:before {
  top: -7px; }

.nav-toggle i:after,
.nav-toggle i:before {
  content: "";
  width: 25px;
  height: 3px;
  background: #505050;
  position: absolute;
  left: 0;
  -webkit-transition: .2s;
  transition: .2s; }

.nav-toggle:hover i:after {
  bottom: -10px; }

.nav-toggle i:after {
  bottom: -7px; }

.blackMagic:after,
.blackMagic:before {
  background: #505050 !important; }

.whiteMagic:after,
.whiteMagic:before {
  background: #666666 !important; }

#close:hover {
  color: #ff2956;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation: glitch;
          animation: glitch;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: .2s;
          animation-duration: .2s; }

#logo_menu {
  position: absolute;
  top: 0%;
  left: 3%; }
  #logo_menu img {
    width: 127px; }

@media screen and (max-width: 768px) {
  #menu p {
    font-size: 18px;
    text-align: center;
    letter-spacing: 5px;
    width: 100%; }
  #menu .aasdfa {
    width: 67%; } }

#menu {
  opacity: 0;
  pointer-events: none; }

footer {
  background: #000000;
  color: #EAEAEA;
  padding: 40px 0px; }
  footer .right {
    text-align: right; }
  footer .left {
    text-align: left; }
  footer .big_hr {
    width: 100%;
    border: 1px solid #EAEAEA; }
  footer .small_hr {
    display: inline-block;
    width: 50px;
    border: 1px solid #EAEAEA; }
  footer h1 {
    font-size: 22px;
    margin: 0; }
  footer p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px; }
  footer img {
    width: 88px;
    margin-top: 21px; }
  footer a {
    color: #383838;
    -webkit-transition: all 1s;
    transition: all 1s;
    font-size: 16px;
    letter-spacing: 1px; }
  footer a:hover {
    text-decoration: none;
    color: #FF2956; }

@media screen and (max-width: 768px) {
  footer .left, footer .right {
    text-align: center; } }

#hero {
  -webkit-transition: all .5s;
  transition: all .5s;
  position: relative;
  height: 100vh;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #000000;
  background-size: cover;
  background-position: center center; }
  #hero img {
    width: 15%;
    height: auto; }
  #hero .redes {
    position: absolute;
    bottom: 3%;
    left: 2%; }
    #hero .redes a {
      margin: 10px 0px;
      display: block;
      font-size: 25px;
      color: #FF2956;
      -webkit-transition: color 1s;
      transition: color 1s; }
    #hero .redes a:hover {
      color: #ff5c7e;
      -webkit-animation: glitch;
              animation: glitch;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
      -webkit-animation-duration: .3s;
              animation-duration: .3s; }

@media screen and (min-width: 1200px) {
  footer .container,
  #somos .container {
    width: 80%; } }

#contacto {
  background-image: url(../images/back_empecemos2.jpg);
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  padding: 120px 0px; }
  #contacto h1 {
    color: #FF2956;
    font-weight: bolder;
    font-size: 40px;
    letter-spacing: 3px; }
  #contacto p {
    color: #EAEAEA; }
  #contacto .center {
    text-align: center; }
  #contacto .right {
    text-align: right; }
  #contacto input {
    border: none;
    border-bottom: 1px solid #EAEAEA;
    background: rgba(0, 0, 0, 0);
    font-size: 20px;
    margin: 30px 0px;
    padding: 3px 0;
    color: #EAEAEA;
    width: 80%;
    -webkit-transition: all 1s;
    transition: all 1s; }
  #contacto input:focus {
    border-bottom: 1px solid #FF2956; }
  #contacto ::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #EAEAEA;
    opacity: 1;
    /* Firefox */ }
  #contacto :-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #EAEAEA;
    opacity: 1;
    /* Firefox */ }
  #contacto ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #EAEAEA;
    opacity: 1;
    /* Firefox */ }
  #contacto .large_message {
    width: 100%; }
  #contacto button {
    padding: 8px 10px;
    background: rgba(0, 0, 0, 0);
    border: 1px solid #EAEAEA;
    color: #EAEAEA;
    font-size: 18px;
    letter-spacing: 3px;
    margin-top: 30px;
    -webkit-transition: all 1s;
    transition: all 1s; }
  #contacto button:hover {
    -webkit-animation: glitch;
            animation: glitch;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-duration: .5s;
            animation-duration: .5s;
    border: 1px solid #FF2956;
    background: #FF2956; }

svg {
  -webkit-animation: glitch;
          animation: glitch;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

#exito {
  height: 100vh;
  padding: 15% 0;
  background: #000000; }
  #exito .container {
    width: 100%; }
  #exito .center {
    text-align: center; }
  #exito svg {
    margin-top: -15%; }
  #exito h1 {
    color: #FF2956;
    font-weight: bolder;
    font-size: 40px;
    letter-spacing: 3px;
    z-index: 3; }
  #exito img {
    z-index: 3;
    margin: 40px 0px;
    max-height: 90px;
    max-width: 90%; }
  #exito .middle_image {
    margin: 70px 0; }
  #exito .last_image {
    margin: 50px 0px;
    max-height: 60px; }
  #exito .col-md-2 {
    text-align: center; }

@media screen and (max-width: 768px) {
  #exito {
    padding: 50px 0px; }
    #exito img {
      margin: 20px 0px !important;
      max-height: 90px; } }

#hacemos {
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: translate(0%);
          transform: translate(0%);
  -webkit-transition: -webkit-transform 2s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: -webkit-transform 2s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 2s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 2s cubic-bezier(0.77, 0.2, 0.05, 1), -webkit-transform 2s cubic-bezier(0.77, 0.2, 0.05, 1);
  background: white;
  height: 100vh;
  overflow: hidden; }
  #hacemos .slide {
    position: relative;
    height: 100vh;
    width: 100vw; }

#somos {
  padding: 80px 0px;
  background: #000000; }
  #somos h1 {
    color: #FF2956;
    font-weight: bolder;
    font-size: 40px;
    letter-spacing: 3px;
    z-index: 3;
    margin-bottom: 50px; }
  #somos h3 {
    color: #FF2956;
    font-weight: bolder;
    font-size: 60px;
    letter-spacing: 3px;
    z-index: 3;
    margin-bottom: 20px; }
  #somos p {
    color: #EAEAEA;
    margin-bottom: 50px;
    font-size: 16px;
    z-index: 3;
    margin-left: 30%;
    text-align: justify;
    word-wrap: break-word; }
  #somos h2 {
    font-size: 20px;
    color: #EAEAEA;
    margin-bottom: 20px;
    z-index: 3; }
  #somos .right {
    text-align: right; }
  #somos img {
    z-index: 3;
    width: 30%;
    min-width: 148px; }

@media screen and (max-width: 768px) {
  #hero img {
    width: 35%;
    height: auto; }
  #hero .redes {
    position: absolute;
    bottom: 3%;
    left: 5%; }
    #hero .redes a {
      margin: 10px 0px;
      font-size: 18px; }
  #somos {
    padding: 40px 0; }
    #somos .right {
      text-align: center; }
    #somos p {
      margin-left: 5px;
      margin-right: 5px;
      font-size: 17px;
      margin-bottom: 30px; }
    #somos img {
      margin-left: 20%;
      margin-bottom: 20px; }
    #somos h1 {
      margin-bottom: 20px;
      font-size: 25px; }
    #somos h2 {
      margin-top: 50px;
      font-size: 20px; }
  #exito h1 {
    font-size: 25px;
    letter-spacing: 3px; }
  #exito img {
    max-height: 90px;
    max-width: 41%; } }

#descanso {
  padding-top: 80px;
  height: 300px;
  width: 100%;
  background: black; }
  #descanso svg {
    right: 0; }

section {
  position: relative;
  overflow: hidden; }

svg {
  position: absolute;
  margin-top: -80px;
  height: 0%;
  width: 0%;
  z-index: 0; }

@media screen and (max-width: 768px) {
  #notANav {
    position: fixed;
    height: 70px;
    background: black;
    width: 100%;
    margin-top: 0px;
    z-index: 4000;
    padding: 0 5%; }
  #descanso {
    padding-top: 50px;
    height: 50vh;
    width: 100%;
    background: black; }
  svg {
    margin-top: -50px; }
  #contacto input {
    width: 100% !important; } }

#menu .redes {
  position: absolute;
  bottom: 3%;
  left: 2%; }
  #menu .redes a {
    margin: 10px 0px;
    display: block;
    font-size: 25px;
    color: #FF2956;
    -webkit-transition: all 1s;
    transition: all 1s; }
  #menu .redes a:hover {
    -webkit-animation: glitch;
            animation: glitch;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-duration: .2s;
            animation-duration: .2s;
    color: #ff5c7e; }

.glitch_this {
  -webkit-animation: glitch;
          animation: glitch;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: .7s;
          animation-duration: .7s; }

.remove_glitch {
  filter: none; }

@-webkit-keyframes glitch {
  from {
    filter: url("#glitch"); }
  to {
    filter: none; } }

@keyframes glitch {
  from {
    filter: url("#glitch"); }
  to {
    filter: none; } }

.glitch_this img {
  height: auto;
  max-width: 100%; }

#hero .logo_svg {
  height: 55%;
  width: 55%; }

#hero .st0 {
  stroke: #000000; }

#linea_hero {
  margin-top: 0px;
  width: 100%;
  height: 100vh; }

#block_magic {
  display: block;
  position: absolute;
  width: 100%;
  height: 100vh; }

.slide p {
  font-size: 16px; }

.slide .corner_logo {
  position: absolute;
  height: 100px;
  bottom: 5%;
  right: 4%; }

.slide0 {
  position: relative; }
  .slide0 img {
    position: absolute; }
  .slide0 .image1 {
    top: 20%;
    right: 10%;
    width: 30%;
    max-width: 500px; }
  .slide0 .image2 {
    max-width: 110px;
    right: 0;
    width: 6.5%;
    top: 3%; }
  .slide0 .image3 {
    width: 17%;
    max-width: 350px;
    bottom: 0;
    right: 0; }
  .slide0 h1 {
    margin-top: 35%;
    font-size: 40px;
    font-weight: bolder;
    color: #FF2956;
    letter-spacing: 3px; }
  .slide0 h2 {
    margin-top: 80%;
    color: #1A1A1A;
    font-size: 16px; }
  .slide0 p {
    color: #1A1A1A; }
  .slide0 hr {
    border: 1px solid #bdbdbd;
    width: 100%; }

.slide1 {
  position: relative; }
  .slide1 .box_texto {
    position: absolute;
    padding: 50px;
    background: #000000;
    width: 35%;
    top: 0;
    left: 50%; }
  .slide1 .massive {
    width: 60%;
    top: 0;
    left: 10%;
    position: absolute; }
  .slide1 h1 {
    font-size: 40px;
    font-weight: bolder;
    letter-spacing: 3px;
    color: #FF2956;
    margin-top: 25%; }
  .slide1 p {
    font-size: 16px;
    color: #FBFBFB; }

.slide2 .tech {
  width: 50%;
  bottom: 0%;
  left: 30%;
  position: absolute; }

.slide2 .col-md-3 {
  height: 60vh;
  background: #1A1A1A; }
  .slide2 .col-md-3 h1 {
    margin-top: 80px;
    color: #FF2956;
    font-size: 40px;
    font-weight: bolder;
    letter-spacing: 3px;
    text-align: right; }

.slide2 .col-md-6 h1 {
  margin-top: 80px;
  font-size: 40px;
  font-weight: bolder;
  letter-spacing: 3px;
  color: #1A1A1A;
  margin-bottom: 30px; }

.slide2 p {
  font-size: 16px;
  color: #1A1A1A; }

.slide3 .team {
  width: 53%;
  bottom: 0;
  left: 35%;
  position: absolute; }

.slide3 .col-md-5 {
  background: black;
  padding: 40px; }
  .slide3 .col-md-5 h1 {
    margin-top: 120px;
    margin-bottom: 80px;
    color: #FF2956;
    font-size: 40px;
    font-weight: bolder;
    letter-spacing: 3px;
    text-align: right; }

.slide3 .col-md-4 {
  padding: 40px; }
  .slide3 .col-md-4 h1 {
    margin-top: 120px;
    font-size: 40px;
    font-weight: bolder;
    letter-spacing: 3px;
    color: #1A1A1A;
    margin-bottom: 30px; }

.slide3 p {
  font-size: 16px;
  color: #EAEAEA;
  line-height: 30px;
  text-align: justify; }

.slide4 .black_box {
  background: #000000;
  padding: 50px 100px; }
  .slide4 .black_box h1 {
    color: #FF2956;
    font-size: 40px;
    font-weight: bolder;
    letter-spacing: 3px; }

.slide4 .container {
  width: 90%; }

.slide4 .chava {
  width: 102%;
  margin-top: -1px; }

.slide4 .col-md-5 {
  padding: 40px; }
  .slide4 .col-md-5 h1 {
    margin-top: 120px;
    font-size: 40px;
    font-weight: bolder;
    letter-spacing: 3px;
    color: #1A1A1A;
    margin-bottom: 30px; }

.slide4 .col-md-4 {
  padding: 40px; }

.slide4 p {
  margin-top: 35%; }

.slide4 p,
.slide4 ul {
  font-size: 16px;
  color: #1A1A1A;
  line-height: 30px;
  text-align: justify; }

.slide5 .black_box {
  background: #000000;
  padding: 50px 100px; }
  .slide5 .black_box h1 {
    color: #FF2956;
    font-size: 40px;
    font-weight: bolder;
    letter-spacing: 3px; }

.slide5 .chava {
  width: 102%;
  margin-top: -1px; }

.slide5 .col-md-4 {
  padding: 40px; }

.slide5 p:nth-child(1) {
  margin-top: 35%; }

.slide5 p:nth-child(2) {
  margin-top: 25px; }

.slide5 p {
  font-size: 16px;
  color: #1A1A1A;
  line-height: 30px;
  text-align: justify; }

.slide6 .box_mkt {
  position: absolute;
  width: 30%;
  top: 0;
  left: 50%; }

.slide6 .mkt {
  position: absolute;
  top: 20%;
  right: 50.7%;
  width: 33%; }

.slide6 .col-md-2 {
  height: 60vh;
  background: #1A1A1A; }

.slide6 .col-md-10 {
  padding-left: 50px; }

.slide6 h1 {
  margin-top: 80%;
  color: #FF2956;
  font-size: 40px;
  font-weight: bolder;
  letter-spacing: 3px; }

.slide6 h2 {
  font-family: 'Roboto Condensed', sans-serif;
  margin-bottom: 31px;
  color: #1A1A1A;
  font-size: 40px;
  font-weight: bolder;
  letter-spacing: 3px; }

.slide6 p {
  font-size: 16px;
  color: #1A1A1A;
  line-height: 30px;
  text-align: justify; }

.slides {
  width: 700vw;
  height: 100vh; }

#hacemos .slide {
  width: 98.9vw;
  overflow: hidden;
  float: left; }

#hacemos {
  overflow: unset;
  width: 100%; }
  #hacemos .slides .slide .arrows {
    position: absolute;
    width: 10%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    bottom: 30%;
    right: 1%;
    z-index: 1000;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    #hacemos .slides .slide .arrows img {
      position: relative;
      cursor: pointer;
      height: 41px;
      width: auto;
      -webkit-animation: none;
              animation: none;
      -webkit-animation-duration: .7s;
              animation-duration: .7s;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite; }

.mobile {
  display: none; }

@media screen and (max-width: 768px) {
  #menu .redes a {
    margin: 8px 0;
    font-size: 18px;
    -webkit-transition: all 1s;
    transition: all 1s; }
  #menu .redes {
    bottom: 2%;
    left: 5%; }
  #close {
    top: 2%; }
  #hero .logo_svg {
    height: 82%;
    width: 112%; }
  #somos h2 {
    font-size: 18px; }
  #somos p {
    font-size: 15px; }
  #descanso {
    padding-top: 50px;
    height: 40vh; }
  #exito {
    height: unset; }
  #linea_hero {
    margin-top: -25px; }
  #contacto input {
    font-size: 15px; }
  #hacemos {
    overflow: hidden; }
  #hacemos .slides .slide .arrows {
    position: absolute;
    width: 33%;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    bottom: 2%;
    right: 33%;
    z-index: 1000;
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
            justify-content: space-between; }
  .slides {
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: translate(0);
            transform: translate(0);
    -webkit-transition: -webkit-transform 2s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: -webkit-transform 2s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: transform 2s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: transform 2s cubic-bezier(0.77, 0.2, 0.05, 1), -webkit-transform 2s cubic-bezier(0.77, 0.2, 0.05, 1); }
  .slide0 .image2 {
    max-width: 115px;
    right: 0px;
    width: 14.5%;
    top: 3%; }
  .slide0 .image1 {
    top: 47%;
    right: 13%;
    width: 48%;
    max-width: 500px; }
  .slide0 .image3 {
    width: 25%;
    max-width: 350px;
    bottom: 31%;
    right: 54%; }
  .slide1 .box_texto {
    position: absolute;
    padding: 30px;
    background: #000;
    width: 80%;
    top: 0;
    left: 20%; }
  #hacemos .slide {
    width: 100vw;
    overflow: hidden;
    float: left; }
  .slide1 .massive {
    width: 77%;
    top: unset;
    bottom: 22%;
    left: 0%;
    position: absolute; }
  .slide .corner_logo {
    position: absolute;
    height: 56px;
    bottom: 5%;
    right: 4%; }
  .slide2 .col-md-3 h1, .slide2 .col-md-6 h1 {
    font-size: 30px; }
  .slide4 .black_box h1, .slide5 .black_box h1 {
    font-size: 30px; }
  .slide2 .col-md-3 {
    width: 35%;
    float: left;
    height: 50vh;
    background: #1a1a1a; }
  .slide2 .col-md-6 {
    width: 65%;
    float: right;
    height: 50vh; }
  .slide2 .tech {
    width: 72%;
    bottom: 14%;
    left: 15%;
    position: absolute; }
  .slide4 .black_box {
    text-align: center;
    background: #000;
    padding: 50px 40px; }
  .mobile {
    display: block; }
  .slide4 .col-md-7 {
    width: 100%; }
  .slide4 .container {
    width: 100%;
    padding: unset; }
  .desktop {
    display: none; }
  .slide4 p, .slide4 ul {
    font-size: 16px;
    color: #f2f2f2;
    line-height: 30px;
    text-align: center; }
  .slide4 p {
    margin-top: 12%; }
  .slide4 .black_box {
    text-align: center;
    background: #000;
    padding: 20px 40px; }
  .slide5 .black_box {
    background: #000;
    padding: 20px 55px;
    text-align: center; }
  .slide5 .chava {
    width: 61%;
    margin-left: -15px;
    margin-top: 200px;
    top: 23%;
    position: absolute; }
  .slide5 p:first-child {
    margin-top: 4%; }
  .slide6 .col-md-2 {
    width: 20%;
    float: left;
    height: 50vh; }
  .slide6 .col-md-10 {
    float: right;
    width: 80%;
    height: 50vh; }
  .slide6 .box_mkt {
    position: absolute;
    width: 80%;
    top: 0;
    left: 10%; }
  .slide6 h1, .slide6 h2 {
    font-size: 30px; }
  .slide6 .col-md-10 {
    padding-left: 15px; }
  .slide6 h1 {
    margin-top: 7%;
    color: #ff2956; }
  .slide6 .mkt {
    position: absolute;
    bottom: 15%;
    right: 24%;
    width: 52%;
    top: unset; }
  .slide3 .col-md-4 h1 {
    margin-top: 50px;
    margin-bottom: 0px;
    text-align: center;
    font-size: 30px;
    font-weight: bolder;
    letter-spacing: 3px;
    color: #1a1a1a;
    padding-bottom: 3px; }
  .slide3 .col-md-4 {
    padding: 40px;
    padding-bottom: 3px; }
  .slide3 .col-md-5 {
    background: #000;
    text-align: center;
    padding: 40px;
    padding-top: 3px; }
  .slide3 .col-md-5 h1 {
    margin-top: 10px;
    margin-bottom: 37px;
    color: #ff2956;
    font-size: 30px;
    font-weight: bolder;
    letter-spacing: 3px;
    text-align: center; }
  .slide3 .team {
    width: 70%;
    bottom: 14%;
    left: 0%;
    z-index: 20;
    position: absolute; } }
