#notANav {
    position: fixed;
    height: 70px;
    width: 100%;
    margin-top: 5px;
    z-index: 4000;
    padding: 0px 3%;

    .nav_right {
        line-height: 70px;
        float: right;
        font-size: 10px;
        text-decoration: none !important;
        transition: all 1s;
        font-weight: lighter;
        cursor: pointer;

        i {
            margin-right: 10px;
            font-size: 24px;
        }

        span {
            display: none;
            letter-spacing: 2px;
        }
    }

    .nav_left {
        line-height: 70px;
        float: left;
        height: 70px;
        width: 200px;

        svg {
            height: 60px;
            transition: all 1s;
        }
    }
}

@media screen and (max-width:768px) {
    #notANav {
        padding: 0px 5%;
    }
}

#menu {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 50000;
    /* margin: -100px 0 0 -50px; */
    padding: 50px;
    padding-top: 125px;
    background: black;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transition: all 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: 0 0;
    transform: translate(100%);
    transition: transform 2s cubic-bezier(.77,.2,.05,1);

    .aasdfa {
        width: 47%;
    }

    p {
        position: relative;
        color: #fff;
        font-weight: lighter;
        font-size: 30px;
        letter-spacing: 20px;
        display: inline-block;
        padding: 0px 5px;
        text-align: center;
        margin: 30px 0px;
        cursor: pointer;
    }
    p:nth-child(even){
        float: right;
    }
    p::before {
        content: "";
        left: 0%;
        right: 0%;
        bottom: 46%;
        position: absolute;
        width: 95%;
        height: 3px;
        background-color: #ff2956;
        visibility: visible;
        -webkit-transform: scaleX(0);
        -moz-transform: scaleX(0);
        -ms-transform: scaleX(0);
        -o-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        -ms-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
    }
    p:hover{
        animation: glitch;
    animation-iteration-count: infinite;
    animation-duration: .3s;
    }
    p:hover::before {
        background-color: #ff2956 !important;
        -webkit-transform: scaleX(1);
        -moz-transform: scaleX(1);
        -ms-transform: scaleX(1);
        -o-transform: scaleX(1);
        transform: scaleX(1);
    }

}

#close {
    cursor: pointer;
    position: absolute;
    top: 5%;
    right: 3%;
    color: white;
    transition: all 1s;
    font-size: 39px;
    text-decoration: none !important;
}
.nav-toggle:hover{
    animation: glitch;
    animation-iteration-count: infinite;
    animation-duration: .2s;
}

.nav-toggle i {
    position: relative;
    margin-bottom: 4px;
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    width: 25px;
    height: 3px;
    font: 700 14px/.4 Helvetica;
    text-transform: uppercase;
    text-indent: -55px;
    background: #505050;
    transition: all .2s ease-out;
}

.nav-toggle:hover i:before {
    top: -10px;
}
.logo_nav{
    height: 25px;
}
.nav-toggle i:before {
    top: -7px;
}

.nav-toggle i:after,
.nav-toggle i:before {
    content: "";
    width: 25px;
    height: 3px;
    background: #505050;
    position: absolute;
    left: 0;
    transition: .2s;
}

.nav-toggle:hover i:after {
    bottom: -10px;
}

.nav-toggle i:after {
    bottom: -7px;
}


.blackMagic:after,
.blackMagic:before {
    background: #505050 !important;
}

.whiteMagic:after,
.whiteMagic:before {
    background: rgb(102, 102, 102) !important;
}

#close:hover {
    color: #ff2956;
    transform: rotate(90deg);
    animation: glitch;
    animation-iteration-count: infinite;
    animation-duration: .2s;
}


#logo_menu {
    position: absolute;
    top: 0%;
    left: 3%;

    img {
        width: 127px;
    }
}


@media screen and (max-width:768px){
    #menu p {
        font-size: 18px;
        text-align: center;
        letter-spacing: 5px;
        width: 100%;
    }
    #menu .aasdfa {
        width: 67%;
    }
}

#menu{
    opacity: 0;
    pointer-events: none;
}