footer{
    background: #000000;
    color: #EAEAEA;
    padding: 40px 0px;
    .right{
        text-align: right;
    }
    .left{
        text-align: left;
    }
    .big_hr{
        width: 100%;
        border: 1px solid #EAEAEA;
    }
    .small_hr{
        display: inline-block;
        width: 50px;
        border: 1px solid #EAEAEA;
    }
    h1{
        font-size: 22px;
        margin: 0;
    }
    p{
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;
    }
    img{
        width: 88px;
        margin-top: 21px;
    }
    a{
        color: #383838;
        transition: all 1s;
        font-size: 16px;
        letter-spacing: 1px;
    }
    a:hover{
        text-decoration: none;
        color: #FF2956;
    }
}

@media screen and (max-width:768px){
    footer{
        .left,.right{
            text-align: center;
        }
    } 
}